import { Injectable, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private idleTimeout: any;
  private idleTimeLimit = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
  private activitySubject: Subject<boolean> = new Subject<boolean>();

  constructor(@Inject(PLATFORM_ID) private platformId: object, private ngZone: NgZone) {
    this.startMonitoring();
  }

  /**
   * Start monitoring user activity if running in the browser.
   */
  private startMonitoring(): void {
    if (isPlatformBrowser(this.platformId)) {
      const activityEvents = ['mousemove', 'keydown', 'click', 'scroll'];

      // Run outside Angular zone for better performance
      this.ngZone.runOutsideAngular(() => {
        activityEvents.forEach(event => {
          window.addEventListener(event, () => this.resetIdleTimer());
        });
      });

      this.resetIdleTimer(); // Initialize the timer
    }
  }

  /**
   * Reset the idle timer on user activity.
   */
  private resetIdleTimer(): void {
    clearTimeout(this.idleTimeout);
    this.activitySubject.next(true);

    this.idleTimeout = setTimeout(() => {
      this.activitySubject.next(false); // Emit idle state after timeout
    }, this.idleTimeLimit);
  }

  /**
   * Observable to track user activity.
   */
  public get activity$(): Subject<boolean> {
    return this.activitySubject;
  }
}
