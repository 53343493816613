import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/base/base.component').then(x => x.BaseComponent),
  },
];

export const ModuleRoutes = RouterModule.forChild(routes);
