import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { AppRoutingModule, routes } from './app-routing.module';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LocalStorageService, TranslationLoaderService } from './core/services';
import { ROOT_LEVEL_MODULES } from './core/constants';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EffectsModule } from '@ngrx/effects';
import {  StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@src/environments/environment';
import { MarkdownModule } from 'ngx-markdown';
import { ApiModule, Configuration, ConfigurationParameters } from './core/api';
import { AuthModule } from './features/auth/auth.module';
import { jwtInterceptorFn } from './core/interceptors';
import {AuthService, BaseNetworkService, HttpService} from '@src/app/core/services';
import {BaseUrlInterceptor, baseUrlInterceptorFn} from '@src/app/core/interceptors/base.url.interceptor';
import {LayoutModule} from '@src/app/features/layout/layout.module';
import {AuthGuard} from '@src/app/core/guards';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import { SettingsModule } from '@src/app/features';

const apiConfigFactory = () => {
  const params: ConfigurationParameters = {
    basePath: '',
    withCredentials: true,
  };
  return new Configuration(params);
};

export const appConfig: ApplicationConfig = {
  providers: [

    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
    LocalStorageService,
    BaseNetworkService,
    HttpService,
    AuthService,
    TranslationLoaderService,
    AuthGuard,
    provideRouter(routes),
    importProvidersFrom(
      LayoutModule,
      ...ROOT_LEVEL_MODULES,
      BrowserModule,
      AppRoutingModule,
      ApiModule.forRoot(apiConfigFactory),
      AuthModule,
      SettingsModule,
      // MatIconModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatListModule,
      StoreModule.forRoot([], {}),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      // provideStore(appStore),
      // provideEffects({}),
      EffectsModule.forRoot([]),
      MarkdownModule.forRoot(),

    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideAnimations(),
    
    provideHttpClient(
      withInterceptors([jwtInterceptorFn, baseUrlInterceptorFn])
    ),
  ],
};

// import { MatButtonModule } from '@angular/material/button';
// // import { MatIconModule } from '@angular/material/icon';
// import { MatListModule } from '@angular/material/list';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { provideAnimations } from '@angular/platform-browser/animations';
// import { AppRoutingModule } from '@app/app-routing.module';
// import { AuthModule } from '@app/core/auth/auth.module';
// import { SettingsModule } from '@app/settings/settings.module';
// import { EffectsModule } from '@ngrx/effects';
// import { StoreModule } from '@ngrx/store';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { MarkdownModule } from 'ngx-markdown';
// import { provideHttpClient, withInterceptors } from '@angular/common/http';
// import { jwtInterceptorFn } from './core/interceptors/jwt.interceptor';
// @Injectable({
//   providedIn: 'root',
// })


//   apiConfigFactory = () => {
//     const params: ConfigurationParameters = {
//       basePath: environment.apiUrl,
//       withCredentials: true,
//     };
//     return new Configuration(params);
//   };

//   appConfig: ApplicationConfig = {
//     providers: [
//       provideRouter(routes),
//       importProvidersFrom(
//         BrowserModule,
//         AppRoutingModule,
//         ApiModule.forRoot(this.apiConfigFactory),
//         AuthModule,
//         SettingsModule,
//         // MatIconModule,
//         MatToolbarModule,
//         MatButtonModule,
//         MatSidenavModule,
//         MatListModule,
//         StoreModule.forRoot([], {}),
//         StoreDevtoolsModule.instrument({
//           maxAge: 25,
//           logOnly: environment.production,
//         }),
//         EffectsModule.forRoot([]),
//         MarkdownModule.forRoot(),
//       ),
//       provideClientHydration(),
//       provideHttpClient(
//         withInterceptors([jwtInterceptorFn])
//       ),
//       provideAnimations(),
//     ],
//   };

//   constructor() {
//     if (environment.developemnet) {
//       this.config.environment = 'developemnet';
//       this.config.apiUrl.backendUrl = '';
//       this.config.apiUrl.frontendUrl = '';
//     }
//     if (environment.uat) {
//       this.config.environment = 'uat';
//       this.config.apiUrl.backendUrl = '';
//       this.config.apiUrl.frontendUrl = '';
//     }
//     if (environment.production) {
//       this.config.environment = 'production';
//       this.config.apiUrl.cmsUrl = '';
//       this.config.apiUrl.backendUrl = '';
//       this.config.apiUrl.frontendUrl = '';
//     }
//   }

//   getConfig(): any {
//     return this.config;
//   }
//   getApplicationConfig(): any {
//     return this.appConfig;
//   }
// }
