import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import RolesEnum = Roles.RolesEnum;

import * as routesList from './core/constants/routes.constant';
import { AuthGuard } from './core/guards';
import { AuthRoleGuard } from './core/guards/auth-role-guards/auth-role.guard';
import { ErrorPageComponent } from './core/shared/components/error-page/error-page.component';
import { Roles } from './features/auth/core/entities/roles-enum';
import { BaseComponent } from './features/layout/views/base/base.component';

export const routes: Routes = [
  {
    path: routesList.AUTH_PATH,
    canActivate: [AuthGuard],
    data: { isFromAuth: true },
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: routesList.DASHBOARD_PATH,
        loadChildren: () => import('./features').then(m => m.DashboardModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
      },
      {
        title: 'Admin Users',
        path: routesList.ADMIN_USER_LISTING,
        loadChildren: () => import('./features').then(m => m.UserManagementModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
      },
      {
        path: routesList.APP_USER_LISTING,
        loadChildren: () => import('./features').then(m => m.AppUserManagementModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
      },
      {
        title:"Interior Designers",
        path: routesList.APP_INTERIOR_DESIGNER,
        loadChildren: () => import('./features').then(m => m.InteriorDesignerManagementModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
      },
      {
        title: 'Homeowner Users',
        path: routesList.APP_HOME_OWNER,
        loadChildren: () => import('./features').then(m => m.HomeOwnerModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
      },
      {
        title: 'Subcategories',
        path: routesList.APP_SUB_CATEGORY,
        loadChildren: () => import('./features').then(m => m.SubCategoryModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
      },
      {
        path: routesList.PROFILE,
        loadChildren: () => import('./features').then(m => m.ProfileModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
      },
      // {
      //   title: 'Dashboard',
      //   path: '',
      //   // canActivate: [AuthRoleGuard],
      //   data: { roles: [RoleEnum.Admin, RoleEnum.Manager, RoleEnum.Sales] },
      //   loadChildren: () =>
      //     import('./home/home.module').then((m) => m.HomeModule),
      // },
      // {
      //   title: 'Users',
      //   path: routesList.SYSTEM_SETTINGS,
      //   canActivate: [AuthRoleGuard],
      //   data: { roles: [RoleEnum.Admin] },
      //   loadChildren: () =>
      //     import('./users/users.module').then((m) => m.UsersModule),
      // },
      {
        title: 'Brands',
        path: routesList.BRANDS,
        loadChildren: () => import('./features').then(m => m.BrandsModule),
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
      },
      {
        title: 'Tags',
        path: routesList.TAGS_STYLE,
        data: { type: routesList.STYLE , roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.TagsModule),
        canActivate: [AuthRoleGuard],
      },
      {
        title: 'Tags',
        path: routesList.TAGS_BASE_MATERIAL,
        data: { type: routesList.BASE_MATERIAL, roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.TagsModule),
        canActivate: [AuthRoleGuard],
      },
      {
        title: 'Tags',
        path: routesList.TAGS_FINISH,
        canActivate: [AuthRoleGuard],
        data: { type: routesList.FINISH, roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.TagsModule),
      },
      {
        title: 'Settings',
        path: routesList.SYSTEM_SETTINGS,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.SettingsModule),
      },
      {
        title: 'Pages',
        path: routesList.PAGES,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.PagesModule),
      },
      {
        title: 'Store',
        path: routesList.STORES,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.StoreManagementModule),
      },
      {
        title: 'Categories',
        path: routesList.CATEGORIES_NEW,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.CategoriesModule),
      },
      {
        title: 'Sales',
        path: routesList.SALES,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.SalesModule),
      },
      {
        title: 'Items',
        path: routesList.ITEMS,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.ItemManagementModule),
      },
      {
        title: 'Jobs',
        path: routesList.JOBS,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.JobsModule),
      },
      {
        title: 'Disputes',
        path: routesList.DISPUTES,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features/disputes/disputes.module').then(m => m.DisputesModule),
      },
      {
        title: 'Articles',
        path: routesList.ARTICLES,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features').then(m => m.ArticlesModule),
      },
      {
        title: 'Blog tags',
        path: routesList.BLOG_TAGS,
        canActivate: [AuthRoleGuard],
        data: { roles: [RolesEnum.SuperAdmin, RolesEnum.ContentCreator, RolesEnum.Operator] },
        loadChildren: () => import('./features/blog-tags/blog-tags.module').then(m => m.BlogTagsModule ),
      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
