import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../services';
import {
  DEFAULT_INVALID_TOKEN_SERVER_RESPONSE,
  DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE,
} from '@src/app/core/constants/system.constant';
import { AuthService } from '@src/app/core/services/auth.service';

/**
 * Interceptor for attaching JWT tokens and handling token-related errors.
 */
export const jwtInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const localStorageService = inject(LocalStorageService);
  const authService = inject(AuthService);

  // Excluded URLs where token injection is not required
  const EXCLUDED_URLS = ['/login', 'amazonaws.com'];

  // Check if token injection is required
  const url: string = request.url.toLowerCase();
  const isTokenRequired = !EXCLUDED_URLS.some((excludedUrl) => url.includes(excludedUrl));

  // Add Authorization header if token is required
  if (isTokenRequired) {
    const token = localStorageService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }

  // Add other default headers (e.g., locale)
  request = request.clone({
    setHeaders: {
      'x-locale': '', // You can populate this dynamically based on the app's settings
    },
  });

  // Intercept HTTP responses and handle token-related issues
  return next(request).pipe(
    tap({
      next: (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          handleResponse(event, isTokenRequired, localStorageService, authService);
        }
      },
      error: (err: any) => {
        if (err instanceof HttpErrorResponse) {
          handleError(err, isTokenRequired, localStorageService, authService);
        }
      },
    }),
  );
};

/**
 * Handles HTTP responses to check for token-related errors.
 */
function handleResponse(
  response: HttpResponse<any>,
  isTokenRequired: boolean,
  localStorageService: LocalStorageService,
  authService: AuthService,
): void {
  if (!isTokenRequired) {
    return; // Skip if token is not required
  }

  const isInvalidTokenResponse =
    response.body &&
    (response.body.message === DEFAULT_INVALID_TOKEN_SERVER_RESPONSE ||
      response.body.message === DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE);

  const isUnauthorized = response.status === 401 || response?.body?.status === 401;

  if (isInvalidTokenResponse || isUnauthorized) {
    notifyUserAndRefreshToken(localStorageService, authService);
  }
}

/**
 * Handles HTTP errors (e.g., 401 Unauthorized).
 */
function handleError(
  error: HttpErrorResponse,
  isTokenRequired: boolean,
  localStorageService: LocalStorageService,
  authService: AuthService,
): void {
  if (!isTokenRequired) {
    return; // Skip if token is not required
  }

  const isUnauthorized = error.status === 401;

  if (isUnauthorized) {
    notifyUserAndRefreshToken(localStorageService, authService);
  }
}

/**
 * Notifies the user and attempts to refresh the token.
 */
function notifyUserAndRefreshToken(
  localStorageService: LocalStorageService,
  authService: AuthService,
): void {
  const token = localStorageService.getToken(true);
  if (token) {
    // Notify the user about session expiration
    authService.handleTokenFailureMessage()
    // Attempt to refresh the token
    authService.refreshToken();
  }
}


