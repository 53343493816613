import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  type: any;
  title: any;
  desc: any;
  private sub: Subscription | any;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');

    this.sub = this.route.data.subscribe((param: any) => {
      if (param.type) {
        this.type = param.type;
      }
      if (param.title) {
        this.title = param.title;
      }
      if (param.desc) {
        this.desc = param.desc;
      }
    });
    if (this.type) {
      this.type = '404';
    }
    switch (this.type) {
      case '404':
        if (!this.title) {
          this.title = this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE');
        }
        if (!this.desc) {
          this.desc = this.translate.instant('COMPONENTS.ERROR_PAGE.DESCRIPTION');
        }
        break;
      case '500':
        if (!this.title) {
          this.title = this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE_INTERNAL_SERVER');
        }
        if (!this.desc) {
          this.desc = this.translate.instant('COMPONENTS.ERROR_PAGE.DESCRIPTION_500');
        }
        break;
      case 'no-access':
        this.type = 'Access Denied';
        if (!this.title) {
          this.title = this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE_ACCESS_DENIED');
        }
        if (!this.desc) {
          this.desc = this.translate.instant('COMPONENTS.ERROR_PAGE.DESCRIPTION_ACCESS_DENIED');
        }
        break;
      default:
        // if (!this.type) {
        this.type = 'Ooops..';
        // }
        if (!this.title) {
          this.title = this.translate.instant('COMPONENTS.ERROR_PAGE.TITLE_SOMETHING_WENT_WRONG');
        }
        if (!this.desc) {
          this.desc = this.translate.instant(
            'COMPONENTS.ERROR_PAGE.DESCRIPTION_SOMETHING_WENT_WRONG',
          );
        }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
