<!-- <div class="main-wrapper">
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <img alt="" src="assets/images/others/404.svg" class="img-fluid mb-2" alt="404" />
          <h1 class="fw-bolder mb-22 mt-2 tx-80 text-muted">{{ type }}</h1>
          <h4 class="mb-2">{{ title }}</h4>
          <h6 class="text-muted mb-3 text-center" [innerHTML]="desc"></h6>
          <a routerLink="">Back to home</a>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- 404 page -->
<div class="page-not-wrapper">
  <div class="page-not-content-box d-flex align-items-center justify-content-center h-100">
    <div class="page-not-content d-flex flex-column align-items-center justify-content-center">
      <a href="#" class="logo mb-4 d-block">
        <h1>SID</h1>
      </a>
      <img class="mt-2 d-block" src="assets/images/404.png" alt="" />
      <h3 class="mb-3 tx-30 fw-700 my-4">
        {{ title }}
      </h3>
      <p class="tx-18 mb-4" [innerHTML]="desc">
        {{ desc }}
      </p>
      <a routerLink="" class="btn btn-primary d-block w-100 mt-2">
        {{ 'COMPONENTS.ERROR_PAGE.BACK_TO_HOMEPAGE' | translate }}
      </a>
    </div>
  </div>
</div>
