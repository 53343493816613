{
  "PAGE_TITLE": "SID - CMS",
  "APP_TITLE": "SID",
  "ENGLISH": "English",
  "FRENCH": "French",
  "WELCOME": "Welcome To SID",
  "COMPONENTS": {
    "DASHBOARD": "Dashboard",
    "USERS": {
      "USER_PROFILE": "User Profile",
      "INTERIOR_DESIGNER_PROFILE": "Interior Designer Profile",
      "PERFORMED_ON": "Performed On",
      "ABOUT": "About",
      "ACTOR": "Actor",
      "TIME": "Time",
      "DATE": "Date",
      "MODULE": "Module",
      "SUB_MODULE": "Sub Module",
      "UPLOAD_PHOTO": "Upload Photo",
      "UPDATE": "Update",
      "FIRST_NAME": "First Name",
      "LAST_NAME": "Last Name",
      "DATE_OF_BIRTH": "Date Of Birth",
      "GENDER": "Gender",
      "MALE": "Male",
      "FEMALE": "Female",
      "ROLE": "Role",
      "CREATION_DATE": "Creation date",
      "EMAIL_ADDRESS": "Email Address",
      "CONTACT_NUMBER": "Contact Number",
      "SELECT_ROLES": "Select Roles",
      "EMAIL": "Email",
      "USER_ID": "Employee ID",
      "LANDLINE_NUMBER": "Landline Number",
      "MOBILE_NUMBER": "Mobile Number",
      "SEARCH_BY_COUNTRY": "Search by Country Name or Code",
      "PASSWORD": "Password",
      "NEW_PASSWORD": "New Password",
      "PASSWORD_INFO": "At least 8 characters, one capital letter, one number, and one special character",
      "CHANGE_PASSWORD": "Change Password",
      "OFFICIAL_DETAILS": "Official Details",
      "OFFICIAL_CONTACT_NUMBER": "Official Contact Number",
      "OFFICIAL_EMAIL_ADDRESS": "Official Email Address",
      "DESIGNATION": "Designation",
      "USER_ADDRESS": "User’s Address",
      "COUNTRY": "Country",
      "SELECT_COUNTRY": "Select Country",
      "CITY": "City",
      "SELECT_CITY": "Select City",
      "COMPLETE_ADDRESS": "Complete Address",
      "USER_DOCUMENTS": "User’s Documents",
      "UPLOAD": "Upload",
      "DRIVING_LICENSE_NUMBER": "Driving License Number",
      "DRIVING_LICENSE_EXPIRY_DATE": "Driving License Expiry Date",
      "NATIONAL_IDENTITY_NUMBER": "National Identity Number",
      "UPLOADED_ON": "Uploaded on",
      "DOWNLOAD": "Download",
      "USER_ACTIVITIES": "User Activities",
      "IMPORT_FILE_NAME": "import-users-template",
      "DOWNLOAD_TEMPLATE": "Dakar Dem Dik - Users - {{date}} - {{time}}",
      "LENGTH_SIX_DIGITS": "Length: 6 Digits",
      "LENGTH_EIGHT_SIXTEEN_CHARACTERS": "Length: 8 ~ 16 characters",
      "ONE_CAPITAL": "At least 1 uppercase letter",
      "ONE_LOWER": "At least 1 lowercase letter",
      "ONE_NUMBER": "At least 1 number",
      "PASSENGER": "Passenger",
      "VALIDATOR": "Validator",
      "GENERAL_INFO": "General Info",
      "SHIPPING_ADDRESS": "Shipping Address",
      "BILLING_ADDRESS": "Billing Address"
    },
    "STORES": {
      "STORE_ID": "ID",
      "STORE_NAME": "Store Name",
      "FIELDS": {
        "STATUS": "Status"
      },
      "STORE_DETAIL": "Store Details",
      "STORE_EMAIL": "Store Email",
      "STORE_ADDRESS": "Store Address",
      "STORE_PHONE_NUMBER": "Store Phone Number",
      "TRANSACTION_NUMBER": "# Transactions",
      "TRANSACTION": "Transactions"
    },
    "TAGS": {
      "TITLE": "Brands",
      "STYLE": "Style",
      "STYLE_TAGS": "Style Tags",
      "BASE_MATERIAL": "Base Material",
      "BASE_MATERIAL_TAGS": "Base Material Tags",
      "FINISH": "Finish",
      "FINISH_TAGS": "Finish Tags",
      "TAG_ID": "Tag ID",
      "TAG_TITLE": "Tag Title",
      "STATUS": "Status",
      "SUCCESS_MESSAGE": "Tag created successfully",
      "UPDATE_SUCCESS": "Tag updated successfully",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Tags",
      "ACTIONS": "Actions"
    },
    "BRANDS": {
      "TITLE": "Tags",
      "BRAND_ID": "ID",
      "BRAND_NAME": "Brand Name",
      "STATUS": "Status",
      "SUCCESS_MESSAGE": "Brand created successfully",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Brands",
      "UPDATE_SUCCESS": "Brand updated successfully",
      "ACTIONS": "Actions"
    },

    "ITEMS": {
      "TITLE": "Tags",
      "ITEM_ID": "ID",
      "ITEM_NAME": "Name",
      "CATEGORY": "Category",
      "CATEGORY_NAME": "Category Name",
      "SUBCATEGORY_NAME":"Subcategory Name",
      "BRAND": "brand",
      "STORE": "Store",
      "STORE_NAME": "Store Name",
      "ALTERNATIVES": "# Alternatives",
      "STATUS": "Status",
      "SUCCESS_MESSAGE": "Item created successfully",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Items",
      "UPDATE_SUCCESS": "Item updated successfully",
      "ACTIONS": "Actions",
      "ID": "ID",
      "NAME": "Item Name",
      "COVER_IMAGE": "Cover Image",
      "IMAGES": "Item Images",
      "UPLOAD_PHOTO": "Upload Photo",
      "ITEM_DESCRIPTION": "Item Description",
      "DESCRIPTION": "Description",
      "ITEM_IMAGES": "Item Images",
      "BRAND_NAME": "Brand Name",
      "ITEM_SKU": "SKU #",
      "FIELDS": {
        "STATUS": "Status"
      },
      "DETAIL": "Item Detail"
    },
    "TASK_AND_PROJECTS":{
      "JOB_TITLE": "Title",
      "JOB_OWNER": "Owner",
      "JOB_DETAIL":"Job Details",
      "JOB_TYPE": "Type",
      "ASSIGNED_ID": "Assigned ID",
      "DOWN_PAYMENT": "Down Payment",
      "FINAL_PAYMENT": "Final Payment",
      "TOTAL_BUDGET": "Total Budget",
      "START_DATE": "Start Date",
      "END_DATE": "Start Date",
      "STATUS": "Status",
      "DESCRIPTION": "Description",
      "LOCATION": "Location",
      "BUDGET_RANGE": "Budget Range",
      "TYPE": "Type",
      "AREA": "Area",
      "STYLE": "Style",
      "NO_OF_ROOMS": "Number of Rooms"
    },
    "ARTICLES": {
      "ID": "ID",
      "TITLE": "Title",
      "TAG": "Tag",
      "CREATED_AT": "created at",
      "STATUS": "Status",
      "ARTICLE_IMAGE": "Article Image",
      "ARTICLE_TITLE": "Article Title",
      "AUTHOR_NAME": "Author Name",
      "ARTICLE_DESCRIPTION": "Article Description",
      "ARTICLE_TAGS": "Tags",
      "SELECT_TAGS": "Select Tags",
      "ARTICLE_CONTENT": "Article Content",
      "PUBLISHED_ON": " Published On",
      "SCHEDULE_PUBLISHING": " Schedule Publishing",
      "ARTICLE_ID": "Article ID"
    },

    "BLOG_TAG": {
      "ID": "ID",
      "TAG_TITLE": "Tag Title",
      "SUCCESS_MESSAGE": "Tag created successfully",
      "UPDATE_SUCCESS": "Tag updated successfully"
    },

    "ROLES": {
      "CREATE_TITLE": "Add Role",
      "TITLE": "Role Details",
      "ROLE": "Role",
      "PERMISSION_TITLE": "Assign Permissions",
      "ADD": "Add Stop",
      "FIELDS": {
        "NAME": "Role Name",
        "NAME_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION_PLACEHOLDER": "i.e Driver",
        "DESCRIPTION": "Description",
        "LATITUDE": "Latitude",
        "LONGITUDE": "Longitude",
        "GEO_LOCATION": "Geo Location",
        "SELECT_GEO_LOCATION": "Select Geo Location"
      }
    },

    "LOGIN": {
      "TITLE": "Login",
      "FIELDS": {
        "EMAIL": "Email Address",
        "PASSWORD": "Password",
        "FORGOT_PASSWORD": "Forgot Password",
        "OTP": "OTP",
        "ENTER_OTP": "Enter OTP",
        "OTP_SENT_TO": "OTP is sent to ",
        "CHECK_INBOX": "please check your inbox",
        "INCASE_YOU_DIDNT_RECEIVE": "incase you didn’t receive it please click",
        "CREDENTIALS_VERIFIED": "Your credentials have been verified. Please proceed to enter the OTP for further authentication.",
        "PLEASE_CHECK_INBOX": "An OTP will be sent to your email address.",
        "UNABLE_TO_LOGIN": "Unable to login",
        "RESEND_OTP": "Resend OTP",
        "USER_LOGGED_IN": "User Logged In Successfully",
        "UNABLE_TO_VERIFY": "Unable to verify OTP",
        "OTP_DISABLED": "Resend OTP button disabled for",
        "OTP_SENT": "An OTP has been sent to your email.",
        "RESET_LINK": "Entered email address is valid. Reset password link sent successfully",
        "UNABLE_TO_RESET": "It seems this link has expired or you've already set your password. Please contact your admin to request a new link!",
        "RESET_LINK_INVALID": "Reset password link is invalid",
        "PASSWORD_RESET_SUCCESSFULLY": "User password added successfully!",
        "PASSWORD_DONOT_MATCH": "Passwords do not match",
        "RESET_PASSWORD_LINK_SENT": "Reset password link will be sent to your email address",
        "RESEND_LINK": "Resend Link",
        "SET_NEW_PASSWORD": "Set New Password",
        "ENTER_NEW_PASSWORD": "Enter New Password",
        "CONFIRM_NEW_PASSWORD": "Confirm New Password",
        "PASSWORD_VALIDATION": "At least 8 characters, one capital letter, one number and one special character"
      }
    },
    "CATEGORIES": {
      "ID": "ID",
      "ADD_CATEGORY": "Add Category",
      "Edit_CATEGORY": "Edit Category",
      "CATEGORY_TITLE": "Category Title",
      "CATEGORY_NAME": "Category Name",
      "SUBCATEGORIES": "# Subcategories",
      "ITEMS": "# Items",
      "STATUS": "Status",
      "SUCCESS_MESSAGE": "Category created successfully",
      "ACTIONS": "Actions"
    },

    "CALENDAR": {
      "TODAY": "Today",
      "HOLIDAY": "Holiday",
      "DAYS_OFF": "Days off",
      "NO_ERRORS": "No Errors",
      "ERRORS": "Errors",
      "SERVICE_VIOLATION": "Service Violation",
      "DAY_OF_VIOLATION": "Day Off Violation",
      "HOURS_LESS_THEN_35": "Hours less than 35 hrs",
      "HOURS_MORE_THAN_48": "Hours more than 48 hrs",
      "MORE_THEN_ONE_DAY_OF_VIOLATION": "More than 1 day off",
      "NOT_ENOUGH_REST": "Not enough rest time",
      "ZERO_DAY_OF_VIOLATION": "0 Days off",
      "DELETE_ROW": "Are you sure you want to delete the row?"
    },

    "NOTIFICATIONS": {
      "HEADING": "Notifications",
      "CREATE": "Add Notification",
      "STATISTICS": "Notifications Statistics",
      "TEMPLATE": "Notifications Templates",
      "DETAILS": "Notifications Details",
      "AUDIENCE": "Notifications Audience",
      "TITLE": "Title",
      "ENTER_TITLE": "Enter Title",
      "DESCRIPTION": "Description",
      "DATE_AND_TIME": "Date & Time",
      "USER": "User",
      "INSERT": "Insert",
      "TARGET_AUDIENCE": "Target Audience",
      "SELECT_TARGET_AUDIENCE": "Select Target Audience",
      "SELECT_DATA": "Select Data",
      "SEND_NOW": "Send Now",
      "SCHEDULE_FOR_LATER": "Schedule for Later",
      "START_DATE": "Start Date",
      "TIME": "Time",
      "ALL_DRIVERS": "All Drivers",
      "ALL_ATTENDANTS": "All Attendants",
      "ALL_INSPECTORS": "All Inspectors",
      "ALL_SALES_AGENT": "All Sales Agent",
      "ALL_COMMERCIAL_SALES_AGENT": "All Commercial Sales Agent",
      "SELECTED_LINES": "Targeted Lines",
      "SELECTED_USERS": "Targeted Users",
      "GENERAL_BROADCAST": "General Broadcast",
      "TEMPLATES": [
        {
          "TITLE": "Maintenance Alert",
          "DESCRIPTION": "Please be informed that scheduled maintenance will take place tomorrow [from - to]. Your services might be temporarily unavailable."
        },
        {
          "TITLE": "Policy Update",
          "DESCRIPTION": "Please note that our company policies have been updated. Review the new policies in the portal to stay informed."
        },
        {
          "TITLE": "Training Session",
          "DESCRIPTION": "A mandatory training session on the new ticketing system will be held on [Date & Time] in [Location]."
        },
        {
          "TITLE": "Service Interruption",
          "DESCRIPTION": "Please ensure all safety protocols are followed during your shifts. Your safety and the safety of our passengers is our top priority."
        },
        {
          "TITLE": "New Passes",
          "DESCRIPTION": "New Passes are available for passengers on [selected months]. Inform your customers to check the website for more details."
        },
        {
          "TITLE": "Holiday Schedule",
          "DESCRIPTION": "Please be aware of the upcoming holiday schedule changes. Check the updated schedule on the portal and plan accordingly."
        },
        {
          "TITLE": "System Update",
          "DESCRIPTION": "A system update will be performed tonight at midnight. Please save your work and log out before this time to avoid data loss."
        },
        {
          "TITLE": "Meeting Reminder",
          "DESCRIPTION": "A reminder for the scheduled meeting with the operations team [Date & Time]. Your attendance is required."
        },
        {
          "TITLE": "Roadblock Ahead",
          "DESCRIPTION": "Please be advised that there is a roadblock on [Route Name] due to construction work. Expect delays and plan your trip accordingly."
        },
        {
          "TITLE": "Emergency Roadblock",
          "DESCRIPTION": "Due to an accident, there is an emergency roadblock on [Area Name]. The road is expected to be cleared in approximately [Number of hours]."
        },
        {
          "TITLE": "Temporary Closure",
          "DESCRIPTION": "Please note that [Area Name] is temporarily closed for a public event. Follow the posted detour signs for alternative routes."
        },
        {
          "TITLE": "Construction Roadblock",
          "DESCRIPTION": "There is a scheduled roadblock on [Area Name] for road repairs. The block will be in place [From - To]."
        },
        {
          "TITLE": "Police Roadblock",
          "DESCRIPTION": "There is an ongoing police investigation causing a roadblock on [Area Name]. Expect delays and rerouted traffic."
        },
        {
          "TITLE": "Peak Hour Inspections",
          "DESCRIPTION": "Focus on ticket and card validations during peak hours today. High traffic expected on [Route Names]."
        },
        {
          "TITLE": "Increased Inspections Required",
          "DESCRIPTION": "Due to recent fraud incidents, increased ticket and card inspections are required on all evening routes. Ensure to conduct thorough checks."
        },
        {
          "TITLE": "Price Adjustment Notification",
          "DESCRIPTION": "Please note that there will be a price adjustment for our services [starting next quarter]. Inform your clients and address any concerns they might have."
        },
        {
          "TITLE": "Temporary Card Unavailability",
          "DESCRIPTION": "Due to a system upgrade, card issuance will be temporarily unavailable on [Friday from 2 PM to 6 PM]. Plan your activities accordingly."
        },
        {
          "TITLE": "Card Serial Number Restriction",
          "DESCRIPTION": "This is to inform you that the following card serial number [insert card serial number] is restricted from use effective immediately. Kindly ensure this card is not issued to any clients or used for transactions."
        },
        {
          "TITLE": "Important Announcement",
          "DESCRIPTION": "We have an important announcement:[Announcement Details] Please ensure that this information is communicated to all relevant parties and adhered to as required."
        },
        {
          "TITLE": "Passenger Count Confirmation",
          "DESCRIPTION": "Confirm the passenger count on [Bus 9] matches with the fare collection. Report any discrepancies immediately."
        }
      ]
    },

    "ERROR_PAGE": {
      "TITLE": "Page Not Found",
      "TITLE_500": "Internal server error",
      "TITLE_ACCESS_DENIED": "Access Denied",
      "TITLE_SOMETHING_WENT_WRONG": "Something went wrong",
      "TITLE_INTERNAL_SERVER": "Internal server error",
      "DESCRIPTION": "The link you followed may be broken or the page may have been removed.",
      "DESCRIPTION_500": "There wan an error. Please try agin later.",
      "DESCRIPTION_ACCESS_DENIED": "You do not have permission to view this page.",
      "DESCRIPTION_SOMETHING_WENT_WRONG": "Looks like something went wrong. We're working on it",
      "BACK_TO_HOMEPAGE": "Back To Homepage"
    },
    "CATEGORY": {
      "SUB_CATEGORY_ID": "ID",
      "SUB_CATEGORY_TITLE": "Subcategory Title",
      "SUB_CATEGORY_DESCRIPTION": "Subcategory Description",
      "SUB_CATEGORY_ORDER": "Subcategory Order",
      "CATEGORY": "Category",
      "NO_OF_ITEMS": "# Items",
      "STATUS": "Status",
      "DELETE": "Delete"
    }
  },
  "COMMON": {
    "TEXT": {
      "URBAN": "Urban",
      "INTERCITY": "Intercity",
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "UNVERIFIED": "Unverified",
      "DRAFT": "Draft",
      "PUBLISHED": "Published",
      "UNPUBLISHED": "Unpublished",
      "PENDING": "Pending",
      "IN_PROGRESS": "In Progress",
      "SENT": "Sent",
      "SCHEDULED": "Scheduled",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "INREPAIR": "In-Repair",
      "INCOMPLETE": "Incomplete",
      "PENDINGREVIEW": "Pending Review",
      "ACCEPTED": "Accepted",
      "REJECTED": "Needs Rework",
      "DONE": "Done",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "NEW": "New",
      "ASSIGNED": "Assigned",
      "NOT_ASSIGNED": "Not Assigned",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "SEARCH": "Search",
      "DATE": "Date",
      "DEPARTURE_TIME": "Departure Time",
      "START_DATE": "Start Date",
      "END_DATE": "End Date",
      "START_TIME": "Start Time",
      "END_TIME": "End Time",
      "SELECT_START_TIME": "Select Start Time",
      "SELECT_END_TIME": "Select End Time",
      "ADD_CUSTOM_TIME": "Add Custom Time 00:00",
      "ENDS_ON": "Ends On",
      "SELECT_DATE": "Select Date",
      "NEVER_ENDS": "Never Ends",
      "OUT_OF_SERVICE": "Out Of Service",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "DURATION": "Duration",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday",
      "SECONDS": "Seconds",
      "OCCURRENCE": "Occurrence",
      "STATUS": "Status",
      "PROFILE_STATUS": "Profile Status",
      "LINE": "Line",
      "DATE_FROM": "Date From",
      "DATE_TO": "Date To",
      "BASED_ON": "Based On",
      "LOCATIONS": "Locations",
      "COUNTRY": "Country",
      "MODULE": "Module",
      "DIRECTION": "Direction",
      "CITY": "City",
      "ROLE": "Role",
      "TYPE_PLACEHOLDER": "Type",
      "FROM_DATE": "From Date",
      "TO_DATE": "To Date",
      "TYPE": "Type",
      "REPEATED_WEEKDAY": "on weekdays ",
      "REPEATED_WEEKEND": "on weekends ",
      "ONE_TIME": "one time ",
      "DAILY": "daily ",
      "CUSTOM": "custom ",
      "ON_MONDAY": "on Monday ",
      "ON_TUESDAY": "on Tuesday ",
      "ON_WEDNESDAY": "on Wednesday ",
      "ON_THURSDAY": "on Thursday ",
      "ON_FRIDAY": "on Friday ",
      "ON_SATURDAY": "on Saturday ",
      "ON_SUNDAY": "on Sunday ",
      "AND": " and ",
      "ON": "on",
      "CATEGORY": "Category",
      "BRAND": "Brand",
      "STORE": "Store",
      "TAG": "Tag",
      "FROM": "From",
      "TO": "To"
    },
    "VALIDATION": {
      "NOT_FOUND": "Not Found",
      "REQUIRED": "Required",
      "INVALID_FORMAT": "Invalid Format",
      "INVALID_EMAIL": "Invalid Email",
      "INVALID_DATE_RANGE": "Invalid Date Range",
      "INVALID_PASSWORD_FORMAT": "Incorrect Password Format",
      "USER_ID_VALIDATION": "Employee ID should be 6 to 8 characters",
      "INVALID_EMAIL_FORMAT": "Invalid email format",
      "ENTER_REQUIRED_FIELDS": "Please enter the required fields!",
      "INVALID_ORDER_VALUE": "Invalid value. Please enter a number between 1 and 99.",
      "TITLE_LIMIT_EXCEED": "Title should be not more than 60 characters.",
      "DESCRIPTION_LIMIT_EXCEED": "Article Description should be not more than 100 characters.",
      "ARTICLE_TITLE_LIMIT_EXCEED": "Article Title should be not more than 90 characters.",
      "ARTICLE_DESCRIPTION_LIMIT_EXCEED": "Article Description should be not more than 150 characters.",
      "ARTICLE_CONTENT_LIMIT_EXCEED": "Article content should be not more than 10000 characters.",
      "AUTHOR_NAME_LIMIT_EXCEED": "Author Name should be not more than 80 characters."
    }
  },
  "GENERAL": {
    "BUTTONS": {
      "SELECT": "Select",
      "NEW": "New",
      "REFRESH": "Refresh",
      "ANONYMOUS": "Anonymous",
      "BLACKLISTED": "Blacklisted",
      "ASSIGNED": "Assigned",
      "VIEW_ON_MAP": "View On Map",
      "CANCEL": "Cancel",
      "APPLY": "Apply",
      "INSERT": "Insert",
      "CONFIRM": "Confirm",
      "LEAVE_PAGE": "Leave Page",
      "FILTERS": "Filters",
      "ACTIONS": "Actions",
      "EXPIRED": "Expired",
      "UPCOMING": "Upcoming",
      "TODAY": "Today",
      "TOMORROW": "Tomorrow",
      "YESTERDAY": "Yesterday",
      "LAST_7_DAYS": "Last 7 Days",
      "CURRENT": "Current",
      "PAST": "Past",
      "NEXT": "Next",
      "CLOSE": "Close",
      "SUBMIT": "Submit",
      "DELETE": "Delete",
      "SAVE": "Save",
      "UPDATE": "Update",
      "RESET": "Reset",
      "ADD": "Add",
      "EDIT": "Edit",
      "CREATE": "Create",
      "ACTIVATE": "Activate",
      "DEACTIVATE": "Deactivate",
      "PUBLISHED": "Published",
      "UNPUBLISHED": "Unpublished",
      "PENDING": "Pending",
      "DAMAGED": "Damaged",
      "OBSOLETE": "Obsolete",
      "OUT_OF_SERVICE": "Out Of Service",
      "INREPAIR": "In-Repair",
      "MAINTENANCE": "Maintenance",
      "DUPLICATE": "Duplicate",
      "DRAFT": "Set as Draft",
      "SAVE_DRAFT": "Save as Draft",
      "SAVE_PENDING": "Set as Pending",
      "SAVE_CHANGES": "Save changes",
      "PUBLISH": "Publish",
      "READY": "Ready",
      "MARK_AS_READY": "Mark As Ready",
      "UNASSIGN": "Unassign",
      "SELECT_ALL": "Select All",
      "UNSELECT_ALL": "Unselect All",
      "SAVE_DRAFT_GRAPH": "Save As Draft & View Graph",
      "DOWNLOAD_TEMPLATE": "Download Template",
      "DOWNLOAD_SELECTED_ROWS": "Download Selected Rows",
      "DOWNLOAD_FILTERED_ROWS": "Download Filtered Rows",
      "IMPORT": "Import",
      "EXPORT": "Export",
      "SET_AS_DRAFT": "Set as Draft",
      "SET_AS_PENDING": "Set as Pending",
      "SAVE_CONFIGURE": "Save & Configure Services",
      "VIEW_ALL_ZONES": "View All Zones",
      "INCOMPLETE": "Incomplete",
      "PENDINGREVIEW": "Pending Review",
      "ACCEPTED": "Accepted",
      "REJECTED": "Needs Rework",
      "UNVERIFIED": "Unverified",
      "UPLOAD": "Upload",
      "CONTRACT": "Contract"
    }
  },
  "MSGS": {
    "CATEGORY": {
      "UPDATE_CATEGORY_STATUS": "Category Activated Successfully!",
      "UPDATE_CATEGORY_STATUS_DEACTIVATED": "Category Deactivated Successfully!",
      "UNABLE_TO_UPDATE_CATEGORY_STATUS": "Unable to update category",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this Category?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this Category?"
    },
    "SUB_CATEGORIES": {
      "NO_SUB_CATEGORY_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Subcategories",
      "UPDATE_SUB_CATEGORY_STATUS": "Subcategory Activated Successfully!",
      "UPDATE_SUB_CATEGORY_STATUS_DEACTIVATED": "Subcategory Deactivated Successfully!",
      "UNABLE_TO_UPDATE_SUBCATEGORY_STATUS": "Unable to update subcategory",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this Subcategory?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this Subcategory?",
      "DELETE_SUB_CATEGORY_SUCCESS":"Subcategory Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete user subcategory",
      "SUB_CATEGORY_CREATED":"Subcategory created successfully",
      "SUB_CATEGORY_UPDATED":"Subcategory updated successfully!",
      "DELETE_SUB_CATEGORY": "Are you sure you want to Delete this Subcategory?"
    },
    "ITEMS": {
      "NO_ITEM_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Items",
      "UPDATE_ITEM_STATUS": "Item Activated Successfully!",
      "UPDATE_ITEM_STATUS_DEACTIVATED": "Item Deactivated Successfully!",
      "UNABLE_TO_UPDATE_SUBCATEGORY_STATUS": "Unable to update item",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this Item?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this Item?",
      "DELETE_ITEM_SUCCESS":"Item Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete user item",
      "ITEM_CREATED":"Item created successfully",
      "ITEM_UPDATED":"Item updated successfully!",
      "DELETE_ITEM": "Are you sure you want to Delete this Item?",
      "INVALID_ITEM_SELECTION": "An item cannot be selected as its own variant or similar item"
    },
    "STORE": {
      "UPDATE_STORE_STATUS": "Store Activated Successfully!",
      "UPDATE_STORE_STATUS_DEACTIVATED": "Store Deactivated Successfully!",
      "UNABLE_TO_UPDATE_STORE_STATUS": "Unable to update store",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this Store?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this Store?",
      "DELETE_TEXT_SUCCESS": "Store Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete store",
      "DELETE_TEXT": "Are you sure you want to delete this store?",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "No records available to display.",
      "CREATE_NEW_TO_DISPLAY": "Click on “New” to create some Stores",
      "CREATE_STORE_SUCCESS_MESSAGE": "Store created successfully",
      "UPDATE_STORE_SUCCESS_MESSAGE": "Store Updated Successfully!",
      "STORE_NAME_VALIDATION": "Store Name must be less than 60 characters"
    },
    "PRODUCT": {
      "UPDATE_PRODUCT_STATUS": "Product Activated Successfully!",
      "UPDATE_PRODUCT_STATUS_DEACTIVATED": "Product Deactivated Successfully!",
      "UNABLE_TO_UPDATE_PRODUCT_STATUS": "Unable to update store",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this Product?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this Product?",
      "DELETE_TEXT_SUCCESS": "Product Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete store",
      "DELETE_TEXT": "Are you sure you want to delete this store?",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "No records available to display.",
      "CREATE_PRODUCT_SUCCESS_MESSAGE": "Product created successfully",
      "UPDATE_PRODUCT_SUCCESS_MESSAGE": "Product Updated Successfully!"
    },
    "ARTICLES": {
      "DELETE_ARTICLE": "Are you sure you want to Delete this Article?",
      "ARE_YOU_SURE_PUBLISH": "Are you sure you want to Publish this Article?",
      "ARE_YOU_SURE_UNPUBLISH": "Are you sure you want to Unpublish this Article?",
      "UPDATE_ARTICLE_STATUS": "Article Published Successfully!",
      "UPDATE_ARTICLE_STATUS_DEACTIVATED": "Article Unpublished Successfully!",
      "DELETE_ARTICLE_SUCCESS":"Article Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete article",
      "UNABLE_TO_UPDATE_Article_STATUS": "Unable to update article",
      "SAVE_SUCCESS": "Article created successfully",
      "UPDATE_SUCCESS": "Article updated successfully!"
    },
    "BLOG_TAG": {
      "DELETE_BLOG_TAG": "Are you sure you want to Delete this Tag?"
    },
    "GENERAL": {
      "INVALID_PHONE_NUMBER": "Invalid phone number",
      "UPDATE_CATEGORY_SUCCESS_MESSAGE": "Category Updated Successfully!",
      "REQUIRED_EMAIL": "Email is required",
      "SOMETHING_WRONG": "Something went wrong",
      "SELECT_ROW": "Please select at least one row.",
      "SELECT_FILTER": "Please select at least one filter.",
      "CONFIRM_DEPARTURE_TIMES": "Please confirm departure times to proceed",
      "CONFIRM_PUBLISH_SERVICES": "Publishing a Service Creation is irreversible, the Service Creation cannot be changed afterward! New timetables created later within this date range no longer be included.",
      "DRAFT_TIMETABLES-DETECTED": "We detect Draft Timetables within the same date range. By publishing, these timetables and new ones will no longer be included.",
      "INVALID_FILE": "Invalid file type! Only jpg, jpeg, png and pdf are allowed",
      "INVALID_IMAGE": "Invalid image type! Only jpg, jpeg, png is allowed",
      "COVER_IMAGE_REQUIRED": "Cover image is required",
      "INVALID_IMAGE_DIMENSION": "Invalid image dimensions!",
      "DONE": "Done",
      "ALL_ZONES": "All Zones",
      "ARE_YOU_SURE": "Are you sure you want to perform this action ?",
      "NO_RESULTS_FOUND": "No Results Found",
      "NO_TRANSACTION_EXISTS": "No transaction was found!",
      "NO_RESULTS_FOUND_GENERIC": "Record does not exist! Click on New or refine your search.",
      "NO_RECORD_FOUND": "No Records Found!",
      "FILE_TOO_LARGE": "The selected file is too large. Please select a file that is less than 200 KB.",
      "IMAGE_TOO_LARGE": "The selected image is too large. Please select an image that is less than 2 MB.",
      "STATUS_UPDATED_SUCCESS": "Status updated successfully",
      "STATUS_UPDATE_FAILED" : "Failed to update status",
      "LOADING": "Loading please wait.",
      "START_DATE_ERROR": "Date from must be earlier than date to.",
      "START_TIME_ERROR": "Start time must be earlier than end time.",
      "END_TIME_ERROR": "End time must be later than start time.",
      "INVALID_END_TIME": "Invalid end time",
      "INVALID_START_TIME_DRIVING": "There should be no driving gap between the End and the Start Time.",
      "INVALID_START_TIME_DRIVING_REST_TIME": "The start time for driving should match the start time of the next trip.",
      "INVALID_START_TIME": "Invalid start time",
      "START_END_TIME_SAME": "Start time and end time cannot be the same",
      "YES": "YES",
      "NO": "NO",
      "DELETE_TAG_SUCCESS": "Tag Deleted Successfully!",
      "DELETE_BRAND_SUCCESS": "Brand Deleted Successfully!",
      "DELETE_BRAND_ALERT": "Are you sure you want to Delete this Brand?",
      "DELETE_TAG_ALERT": "Are you sure you want to delete this Tag?",
      "STOP": "Stop",
      "BLOCKS_MISSING": "Trip blocks are required",
      "UNASSIGN_ROWS": "Are you sure you want to unassign",
      "MARK_PLAN_READY_CONFIRMATION": "Are you sure you want to Mark this plan ready?",
      "UNSAVED_CHANGES": "Are you sure you want to leave the screen without saving the changes?",
      "VIEW_MAP_MIN_STOPS_ERROR": "There should be atleast 2 stops to view map",
      "DUPLICATE_END_TIME": "Invalid Data! Two services shouldn’t have same end time",
      "DUPLICATE_START_TIME": "Invalid Data! Two services shouldn’t have same start time",
      "INVALID_START_END_TIME": "Start time or end time cannot be '00:00' in a service.",
      "INVALID_START_TIME_OVERLAP": "Start Time of the services should not overlap.",
      "SERVICE_EXCEEDS_10_HRS": "Service duration exceeds 10 hours",
      "END_TIME_AFTER_START_TIME": "End Time of service cannot be after the start time of next service",
      "INVALID_START_TIME_DIFFERENCE": "The time difference between the end tme of the previous service and the start time of the next service should be at least one minute",
      "START_END_TIME_OVERLAP_TRIPS": "Start time or end time overlap with existing trips",
      "SHOW_DEPARTURES_VALIDATION_ERROR": "Invalid input: Start time, end time, or interval is missing or invalid.",
      "NO_SUB_CATEGORY_AVAILABLE_TO_DISPLAY": "Click on “New” to create some Subcategories",
      "NO_RECORD_AVAILABLE_TO_DISPLAY_FOR_CREATION": "Click on 'New' to create your first entry.",
      "NO_RECORD_AVAILABLE_TO_DISPLAY": "No records available to display.",
      "CLICK_ON_NEW_TO_CREATE_YOUR_FIRST_ENTRY": "Click on 'New' to create your first entry.",
      "INVALID_EMAIL_ADDRESS": "Invalid email address",
      "NAMES_SHOULD_NOT_CONTAIN_NUMBERS": "Names should not contain numbers.",
      "INVALID_UPLOADING_IMAGE": "Uploading more than 6 images is not allowed",
      "DELETE_BOG_TAG_SUCCESS": "Tag Deleted Successfully!",
      "LOAD_FAILED": "Failed to load data"
    },

    "USERS": {
      "EDIT_SUPER_ADMIN": "Super Admin cannot be edited",
      "DELETE_TEXT": "Are you sure you want to delete this user?",
      "CREATE_TEXT": "User created successfully",
      "SAVE_AS_DRAFT_TEXT": "User saved as pending successfully",
      "UPDATE_TEXT": "User detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update user",
      "DELETE_TEXT_SUCCESS": "User Deleted Successfully!",
      "UNABLE_TO_DELETE": "Unable to delete user",
      "UPDATE_TEXT_STATUS": "The Selected user(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update user status",
      "UPDATE_PASSWORD": "User password updated successfully",
      "UNABLE_TO_UPDATE_PASSWORD": "Unable to update user password",
      "DELETE_USER": "Are you sure you want to delete this user?",
      "CREATE_USER": "User created successfully",
      "SAVE_AS_DRAFT_USER": "User saved as draft successfully",
      "UPDATE_USER_PROFILE": "User profile updated successfully",
      "UPDATE_PROFILE": "Profile Updated Successfully! Please refresh the page or log out and log back in to view the changes.",
      "UNABLE_TO_UPDATE_PROFILE": "Unable to update user profile",
      "UPDATE_USER_ADDRESS": "User address updated successfully",
      "UNABLE_TO_UPDATE_ADDRESS": "Unable to update user address",
      "UPDATE_USER_DETAILS": "User detail updated successfully",
      "UNABLE_TO_UPDATE_DETAILS": "Unable to update user details",
      "UPDATE_USER_DOCUMENTS": "User document updated successfully",
      "UPDATE_UPLOAD_USER_DOCUMENTS": "User documents updated and uploaded successfully",
      "UNABLE_TO_UPDATE_DOCUMENTS": "Unable to update user documents",
      "UNABLE_TO_CREATE": "Unable to create user",
      "DELETE_DOCUMENT_SUCCESS": "Document deleted successfully",
      "UNABLE_TO_DELETE_DOCUMENT": "Unable to delete document",
      "DOWNLOAD_DOCUMENT_SUCCESS": "Document downloaded successfully",
      "UNABLE_TO_DOWNLOAD_DOCUMENT": "Unable to download document",
      "DELETE_CATEGORY_SUCCESS": "Category Deleted Successfully!",
      "DELETE_USER_SUCCESS": "User Deleted Successfully!",
      "UPDATE_USER_STATUS": "User Status Activated Successfully!",
      "UPDATE_USER_STATUS_DEACTIVATED": "User Status Deactivated Successfully!",

      "UNABLE_TO_UPDATE_USER_STATUS": "Unable to update user status",
      "UNABLE_TO_DOWNLOAD_TEMPLATE": "Unable to download template",
      "UNABLE_TO_EXPORT": "Unable to export users",
      "UNABLE_TO_EXPORT_NFC": "Unable to export NFC cards",
      "ONLY_EXCEL_FILE_ERROR": "Invalid file type! Only .xls and .xlsx are allowed",
      "ONLY_CSV_FILE_ERROR": "Invalid file type! Only .csv is allowed",
      "SELECT_FILE": "Please choose a file.",
      "IMPORT_USERS": "The import process has started. You will be notified via email upon its completion.",
      "UNABLE_TO_IMPORT": "The import process could not be started. Revise the submitted file",
      "UNABLE_TO_DELETE_SUPER_ADMIN": "One or more selected users have the 'super_admin' role. Users with 'super_admin' roles cannot be deleted.",
      "OWN_PROFILE_DELETION": "You cannot deactivate or delete your user profile",
      "USER_HAS_NO_ACTIVITIES": "The user has no activities",
      "NO_RECORDS_AVAILABLE_TO_DISPLAY": "No records available to display.",
      "SUPER_ADMIN_STATUS_CHANGE": "User of this role cannot change status of Super Admin",
      "NO_HOMEOWNER_AVAILABLE_TO_DISPLAY": "No Homeowner users available to display. Please contact your system administrator for assistance.",
      "PASSWORD_REGENERATED_SUCCESSFULLY": "Password regenerated successfully!",
      "PASSWORD_UPDATED_SUCCESSFULLY": "Password Updated Successfully!",
      "STATE_THE_REASON": "State the reason",
      "REJECTION_DIALOG_TITLE": "Reason",
      "NO_USER_AVAILABLE_TO_DISPLAY": "Click on 'New' to create some Users.",
      "ARE_YOU_SURE_ACTIVATE": "Are you sure you want to Activate this User?",
      "ARE_YOU_SURE_DEACTIVATE": "Are you sure you want to Deactivate this User?",
      "ARE_YOU_SURE_ACCEPT": "Are you sure you want to Accept this User?",
      "ARE_YOU_SURE_REJECT": "Are you sure you want to Reject this User?"
    },
    "ROLES": {
      "DELETE_ROLES": "Are you sure you want to delete this role?",
      "CREATE_TEXT": "Role created successfully",
      "SAVE_AS_DRAFT_TEXT": "Role saved as draft successfully",
      "CREATE_TEXT_PERMISSION": "Permission created successfully",
      "SAVE_AS_DRAFT_TEXT_PERMISSION": "Permission saved as draft successfully",
      "CREATE_ROLE_FIRST": "Create role first to assign it's permissions",
      "UPDATE_TEXT_PERMISSION": "Permission detail updated successfully",
      "UPDATE_TEXT": "Role detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update role",
      "UNABLE_TO_CREATE": "Unable to create role",
      "UNABLE_TO_UPDATE_PERMISSION": "Unable to update permission",
      "UNABLE_TO_CREATE_PERMISSION": "Unable to create permission",
      "DELETE_TEXT_SUCCESS": "Role deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete role",
      "UPDATE_TEXT_STATUS": "The Selected role(s) status updated successfully",
      "UNABLE_TO_UPDATE_TEXT_STATUS": "Unable to update role status"
    },

    "NOTIFICATIONS": {
      "DELETE_NOTIFICATION": "Are you sure you want to delete this notification?",
      "CREATE_NOTIFICATION": "Notification created successfully",
      "CREATE_NOTIFICATIONS": "Notifications created successfully",
      "SAVE_AS_DRAFT_NOTIFICATION": "Notification saved as draft successfully",
      "UPDATE_NOTIFICATION": "Notification detail updated successfully",
      "UNABLE_TO_UPDATE": "Unable to update notification",
      "UNABLE_TO_CREATE": "Unable to create notification",
      "UNABLE_TO_CREATES": "Unable to create notifications",
      "DELETE_NOTIFICATION_SUCCESS": "Notification deleted successfully",
      "UNABLE_TO_DELETE": "Unable to delete notification",
      "UPDATE_NOTIFICATION_STATUS": "The Selected notification(s) status updated successfully",
      "UNABLE_TO_UPDATE_NOTIFICATION_STATUS": "Unable to update notification status"
    }
  },
  "BREADCRUMBS": {
    "USER_MANAGEMENT": {
      "TITLE": "Users Management",
      "USER_LISTING": "Users",
      "ADMIN_USER": "Admin User",
      "APP_USER": "App User",
      "ADD_USER": "Add User",
      "EDIT_USER": "Edit User",
      "HOME_OWNER_DETAIL": "Homeowner Details",
      "INTERIOR_DESIGNER_DETAIL": "Interior Designer Details"
    },
    "SUB_CATEGORY": {
      "ADD_SUB_CATEGORY": "Add Subcategory",
      "EDIT_SUB_CATEGORY": "Edit Subcategory"
    },
    "PRODUCTS_MANAGEMENT": {
      "TITLE": "Products Management",
      "PRODUCT_LISTING": "Products",
      "ITEMS": "Items ",
      "ADD_PRODUCT": "Add Item",
      "EDIT_PRODUCT": "Edit Item"
    },
    "STORE_MANAGEMENT": {
      "TITLE": "Stores Management",
      "STORE_LISTING": "Stores",
      "ADD_STORE": "Add Store",
      "EDIT_STORE": "Edit Store"
    },
    "BLOGS": {
      "TITLE": "blogs",
      "ADD_ARTICLE": "Add Article",
      "EDIT_ARTICLE": "Edit Article",
      "BLOG_TAGS": "Blog tags"
    },
    "TASK_AND_PROJECTS":{
      "TITLE": "Tasks & Projects",
      "JOB_DETAIL": "Job Detail",
      "EDIT_JOB": "Edit Job"
    },
    "DASHBOARD": {
      "TITLE": "Dashboard"
    },
    "PROFILE": {
      "TITLE": "Profile"
    },
    "SYSTEM_LOGS": {
      "TITLE": "System Logs"
    },
    "BRANDS": {
      "TITLE": "Brands"
    },
    "ITEMS": {
      "TITLE": "Items"
    },

    "ROLES_AND_PERMISSIONS": {
      "TITLE": "Roles & Permissions",
      "ACTIVE_ROLE_LIST": "Active Role List",
      "CREATE_ROLE": "Add Role",
      "EDIT_ROLE": "Edit Role"
    }
  },
  "PAGINATION": {
    "SHOWING": "Showing",
    "TO": "to",
    "OF": "of",
    "ENTERIES": "entries"
  },
  "MENU": {
    "Main": "Main",
    "DASHBOARD": "Dashboard",
    "BRANDS": "Brands",
    "ITEMS": "Items",
    "MODULE": "MODULE",
    "ADMIN_USERS": "Admin Users",
    "USERS": "Users",
    "APP_USERS": "App Users",
    "INTERIOR_DESIGNER": "Interior Designer",
    "HOME_OWNER": "Homeowner",
    "HOME_OWNER_USERS": "Homeowner Users",
    "ORDERS": "Orders",
    "RETURNS": "Returns",
    "PAYMENTS_METHODS": "Payments Methods",
    "DELIVERY_METHODS": "Delivery Methods",
    "PRODUCTS": "Items",
    "CATEGORIES": "Categories",
    "SUB_CATEGORIES": "Subcategories",
    "TAGS": "Tags",
    "STYLE": "Style",
    "BASE_MATERIAL": "Base Material",
    "FINISH": "Finish",
    "PAGES": "Pages",
    "SETTINGS": "Settings",
    "USER_MANAGEMENT": "Users Management",
    "SYSTEM_SETTINGS": "System Settings",
    "STORE_MANAGEMENT": "Stores Management",
    "PRODUCTS_MANAGEMENT": "Products Management",
    "STORES": "Stores",
    "TASK_AND_PROJECTS": "Tasks & Projects",
    "TRANSACTIONS": "Transactions",
    "BLOGS": "Blogs",
    "BLOG_TAGS": "Blog Tags",
    "ARTICLES": "Articles",
    "JOBS":"Jobs"
  },
  "TABLE": {
    "BASED_ON": "Based On",
    "PERMISSIONS": "Permissions"
  },
  "CONSTANTS": {
    "DEACTIVATED": "Deactivated",
    "DELETED": "Deleted",
    "ADMIN_USER": "Admin User",
    "APP_USER": "App User",
    "SINGLE": "Single",
    "BULK": "Bulk",
    "IN": "In",
    "OUT": "Out",
    "ONBOARD": "On-Board",
    "HANDHELD": "Hand-Held",
    "COMMERCIAL": "Commercial",
    "LAPTOP": "Laptop",
    "CAMERA": "Camera",
    "THERMAL_PRINTER": "Thermal Printer",
    "CARD_PRINTER": "Card Printer",
    "NORMAL_PRIORITY": "Normal Priority",
    "SYSTEM": "System",
    "HIGH_PRIORITY": "High Priority",
    "ONE_TIME": "One Time",
    "REPEATED_WEEKDAY": "Repeat Weekdays",
    "REPEATED_WEEKEND": "Repeat Weekends",
    "DAILY": "Daily",
    "CUSTOM": "Custom",
    "STATUS": {
      "ACTIVE": "Active",
      "INACTIVE": "Inactive",
      "DRAFT": "Draft",
      "PENDING": "Pending",
      "PUBLISHED": "Published",
      "IN_PROGRESS": "In Progress",
      "READY": "Ready",
      "CANCELLED": "Cancelled",
      "DONE": "Done",
      "OUTOFSERVICE": "Out Of Service",
      "MAINTENANCE": "Maintenance",
      "SETTLED": "Settled",
      "UNSETTLED": "Unsettled",
      "SENT": "Sent"
    }
  }
}
